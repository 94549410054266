import React from "react";
import Logo from '../assets/Logo/Logo.svg'
import Input_img from '../assets/Logo/search-icon.svg'
import banner from '../assets/LandingPage/top-img.png'
import FilterUni from '../assets/LandingPage/filter-uni.svg'
import filter from '../assets/LandingPage/filter.svg'
import Layer1 from '../assets/LandingPage/Layer_1.svg'
import clglogo1 from '../assets/UniversityLogo/Andhra University.png'
import clglogo2 from '../assets/UniversityLogo/BIMTECH.png'
import clglogo3 from '../assets/UniversityLogo/Golden Gate University.png'
import clglogo4 from '../assets/UniversityLogo/IIIT Bangalore.png'
import clglogo5 from '../assets/UniversityLogo/Jain.png'
import clglogo6 from '../assets/UniversityLogo/LIBA.png'
import clglogo7 from '../assets/UniversityLogo/Maninipal.png'
import clglogo8 from '../assets/UniversityLogo/bharathidasan-university.png'
import clglogo9 from '../assets/UniversityLogo/upgrad.png'
import month from '../assets/icons/date.svg'
import star from '../assets/icons/Star 8.svg'
import vetor from '../assets/icons/Vector.svg'
import girls from '../assets/Logo/screen_2x.webp'
import partner from '../assets/Logo/Group 141.svg'
import landing from '../assets/LandingPage/Group 160.svg'

import partner1 from '../assets/patners/Group 170.svg'
import partner2 from '../assets/patners/Group 171.svg'
import partner3 from '../assets/patners/Group 172.svg'
import partner4 from '../assets/patners/Group 191.png'
import partner5 from '../assets/patners/Group 174.svg'
import video from '../assets/aboutus/video.svg'
import OurJourney from '../assets/aboutus/ourjurney.svg'
import patnership from '../assets/aboutus/patner.svg'
import Groupimg from '../assets/aboutus/groupimg.svg'
import backgroundImg from '../assets/aboutus/Group 189.svg'
import germany from '../assets/country/germany_5315745.png'
import canada from '../assets/country/canada_9906451.png'
import finland from '../assets/country/finland_16022600.png'
import usa from '../assets/country/usa_4628635.png'
import Indiaflag from '../assets/country/india.png'
import frances from '../assets/country/france.png'
const Assets = {
    Logo: Logo,
    Search_input: Input_img,
    banner: banner,
    FilterUni: FilterUni,
    filter: filter,
    Layer1: Layer1,
    clglogo1: clglogo1,
    clglogo2: clglogo2,
    clglogo3: clglogo3,
    clglogo4: clglogo4,
    clglogo5: clglogo5,
    clglogo6: clglogo6,
    clglogo7: clglogo7,
    clglogo8: clglogo8,
    clglogo9: clglogo9,
    star: star,
    month: month,
    vetor: vetor,
    girls: girls,
    partner: partner,
    landing: landing,
    partner1: partner1,
    partner2: partner2,
    partner3: partner3,
    partner4: partner4,
    partner5: partner5,
    Video: video,
    OurJourney: OurJourney,
    patnership: patnership,
    Groupimg: Groupimg,
    backgroundImg: backgroundImg,
    Indiaflag: Indiaflag,
    germany: germany,
    canada: canada,
    finland: finland,
    usa: usa,
    frances: frances,

}
export default Assets