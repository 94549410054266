import React, {
  Suspense,
  useEffect,
} from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import DefaultLayout from "./layout/DefaultLayout";
function App() {
  return (

    <Router>
      <Routes>
        <Route path="/*" name="get" element={<DefaultLayout />} />
      </Routes>
    </Router>
  );
}

export default App;
