// routes.js
import Landingpage from "../pages/landingPage/Landingpage";
import AboutusPage from "../pages/Aboutus/AboutusPage";
import Couses from "../pages/couses/Couses";
import Sample from "../pages/sample";
import CollegeView from "../pages/CollegeView/CollegeView";
import WebinarRegistration from "../pages/studyabroad/WebinarRegistration";
import AbroadUniversity from "../pages/AbroadUnisersity/AbroadUniversity";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import AUpayment from '../pages/AUpayment/AUpayment';
import signup from "../components/SignUp/SignUp";
import MobileMenu from "../components/MobilestudyAbroad/MobileMenu";
import BApayment from "../pages/BApayment/BApayment";
import Google from '../pages/GooglePay/Gpay';
import BharathidasanUniversity from '../pages/BharathidasanUniversity/BharathidasanUniversity';
import AndhraUniversity from '../pages/Andhra-University/Andhra-University';
import MGRuniversity from '../pages/MGRuniversity/MGRuniversity';
import ManipalUniversity from '../pages/ManipalUniversity/ManipalUniversity';
import JainOnline from '../pages/JainOnline/JainOnline';
import GoldenGateUniversity from '../pages/GoldenGateUniversity/GoldenGateUniversity';
import IIITBangalore from '../pages/IIITBangalore/IIITBangalore';
import BIMTECH from '../pages/BIMTECH/BIMTECH';
import Liba from '../pages/Liba/Liba';
import ClarkUniversity from '../pages/ClarkUniversity/ClarkUniversity';
import InternationalUniversity from '../pages/InternationalUniversity/InternationalUniversity';
import ESDESBusinessSchool from '../pages/ESDESBusinessSchool/ESDESBusinessSchool';
import Aivancity from '../pages/Aivancity/Aivancity';
import NortheasternUniversity from '../pages/NortheasternUniversity/NortheasternUniversity';
import HaagaHeliaUniversity from '../pages/HaagaHeliaUniversity/HaagaHeliaUniversity';
import VaasaUniversity from '../pages/VaasaUniversity/VaasaUniversity';
import MetropoliaUniversity from '../pages/MetropoliaUniversity/MetropoliaUniversity';
import SeinajokiUniversity from '../pages/SeinajokiUniversity/SeinajokiUniversity';
import InternationalBusinessUniversity from '../pages/InternationalBusinessUniversity/InternationalBusinessUniversity';
import ColoradoStateUniversity from '../pages/ColoradoStateUniversity/ColoradoStateUniversity';
import UniversityCourses from '../pages/Adv/UnivercityCourses';
import PGDM from '../pages/Adv/pgdm';
import BDU from '../pages/Adv/BDU';
import AboutusPageSample from '../pages/AboutusPagesample/AboutusPageSample';
import MGRuniversityPayment from '../pages/MGRpayment/MGRpaymnet';
import NotFound from "../pages/NotFound/Notfount";


const routes = [
  {
    path: "/",
    name: "landingpage",
    element: Landingpage,
    exact: true,
    title: "123admissions | No.1 online Education Partner",
    description: "Experience excellence in education with 123admissions in Masters(MBA,MCA,MA,MSE),UG and study abroad. The trusted global partner for students and working professionals who are seeking top-tier educational opportunities can start your journey now!",
    keywords: "Online education,MBA courses,Study Abroad,Education, Free courses,upgrad,Liba,Admissions,Admissions open,Best university, Less fees online MBA, Study in US, Free certificate,Cambridge university, oxford, 123admissions,Students,Edtech,admissions open, college"
  },
 
  {
    path: "/about",
    name: "AboutUsPage",
    element: AboutusPage,
    exact: true,
    title: "About Us",
    description: "Learn more about our mission, vision, and the team behind My App. Our About Us page provides insights into our values and the services we offer.",
    keywords: "about us, company mission, team, services"
  },
  {
    path: "/sample",
    name: "Sample Page",
    element: Sample,
    exact: true,
    title: "Sample Page - Explore Our Features",
    description: "Explore the Sample page to get a glimpse of the various features and functionalities offered by My App.",
    keywords: "sample, app features, functionalities, preview"
  },
  {
    path: "/collegeview",
    name: "College View",
    element: CollegeView,
    exact: true,
    title: "College View - Detailed Information and Insights",
    description: "View detailed information and insights about a range of colleges to help you make informed educational choices.",
    keywords: "college view, college information, educational choices"
  },
  {
    path: "/WebinarRegistration",
    name: "Webinar Registration",
    element: WebinarRegistration,
    exact: true,
    title: "Webinar Registration - Join Our Educational Webinars",
    description: "Register for our upcoming educational webinars and stay updated with the latest trends and opportunities in higher education.",
    keywords: "webinar registration, educational webinars, online learning"
  },
  {
    path: "/abroad/view",
    name: "Abroad University",
    element: AbroadUniversity,
    exact: true,
    title: "Abroad University - Explore International Education Options",
    description: "Explore universities abroad and find the perfect fit for your international education aspirations.",
    keywords: "abroad universities, international education, study abroad opportunities"
  },
  {
    path: "/privacypolicy",
    name: "Privacy Policy",
    element: PrivacyPolicy,
    exact: true,
    title: "Privacy Policy - Protecting Your Data",
    description: "Read our Privacy Policy to understand how we collect, use, and protect your personal data while using My App.",
    keywords: "privacy policy, data protection, user privacy, terms and conditions"
  },
  {
    path: "/Andhra-University/Payment",
    name: "Andhra University Payment",
    element: AUpayment,
    exact: true,
    title: "Andhra University Payment - Secure Payment Portal",
    description: "Make secure payments for Andhra University through our dedicated payment portal.",
    keywords: "Andhra University, payment, secure portal, student fees"
  },
  {
    path: "/Bharathidasan-University/Payment",
    name: "Bharathidasan University Payment",
    element: BApayment,
    exact: true,
    title: "Bharathidasan University Payment - Convenient Payment Options",
    description: "Complete your payments for Bharathidasan University conveniently through our online payment system.",
    keywords: "Bharathidasan University, payment, online payment, student fees"
  },
  {
    path: "/MGR-University/Payment",
    name: "MGR University Payment",
    element: MGRuniversityPayment,
    exact: true,
    title: "MGR University Payment - Manage Your Payments Efficiently",
    description: "Handle your MGR University payments efficiently using our secure and user-friendly payment portal.",
    keywords: "MGR University, payment, secure payment, university fees"
  },
  {
    path: "/signup",
    name: "Sign Up",
    element: signup,
    exact: true,

  },
  {
    path: "/menu",
    name: "Mobile Menu",
    element: MobileMenu,
    exact: true,

  },

  {
    path: "/University/BharathidasanUniversity",
    name: "Bharathidasan University",
    element: BharathidasanUniversity,
    exact: true,
    title: "Bharathidasan University - Academic Excellence",
    description: "Discover the academic programs and opportunities at Bharathidasan University, a hub of excellence in education.",
    keywords: "Bharathidasan University, academic programs, education excellence"
  },
  {
    path: "/University/AndhraUniversity",
    name: "Andhra University",
    element: AndhraUniversity,
    exact: true,
    title: "Andhra University - Premier Education",
    description: "Explore Andhra University’s programs and learn about its role as a leading institution in higher education.",
    keywords: "Andhra University, education, academic programs"
  },
  {
    path: "/University/MGRuniversity",
    name: "MGR University",
    element: MGRuniversity,
    exact: true,
    title: "MGR University - Premier Learning Hub",
    description: "Find out more about MGR University’s diverse programs and its commitment to providing high-quality education.",
    keywords: "MGR University, learning hub, educational programs"
  },
  {
    path: "/University/ManipalUniversity",
    name: "Manipal University",
    element: ManipalUniversity,
    exact: true,
    title: "Manipal University - Leading Educational Institute",
    description: "Learn about Manipal University and its extensive range of programs designed to prepare students for success.",
    keywords: "Manipal University, educational programs, success"
  },
  {
    path: "/University/JainOnline",
    name: "Jain Online",
    element: JainOnline,
    exact: true,
    title: "Jain Online - Flexible Learning Opportunities",
    description: "Discover Jain Online’s flexible learning opportunities that cater to your educational needs and goals.",
    keywords: "Jain Online, online learning, flexible education"
  },
  {
    path: "/University/GoldenGateUniversity",
    name: "Golden Gate University",
    element: GoldenGateUniversity,
    exact: true,
    title: "Golden Gate University - Innovative Education",
    description: "Explore the innovative programs and educational opportunities offered by Golden Gate University.",
    keywords: "Golden Gate University, innovative education, academic programs"
  },
  {
    path: "/University/IIITBangalore",
    name: "IIIT Bangalore",
    element: IIITBangalore,
    exact: true,
    title: "IIIT Bangalore - Technology and Innovation",
    description: "Learn about IIIT Bangalore and its focus on technology and innovation in higher education.",
    keywords: "IIIT Bangalore, technology, innovation, higher education"
  },
  {
    path: "/University/BIMTECH",
    name: "BIMTECH",
    element: BIMTECH,
    exact: true,
    title: "BIMTECH - Business and Management Education",
    description: "Get detailed information about BIMTECH and its programs specializing in business and management education.",
    keywords: "BIMTECH, business education, management programs"
  },
  {
    path: "/University/Liba",
    name: "Liba",
    element: Liba,
    exact: true,
    title: "Liba - Advanced Learning Opportunities",
    description: "Explore Liba’s advanced learning opportunities and educational programs tailored to enhance your skills and knowledge.",
    keywords: "Liba, advanced learning, educational programs"
  },
  {
    path: "/University/ClarkUniversity",
    name: "Clark University",
    element: ClarkUniversity,
    exact: true,
    title: "Clark University - Academic Excellence and Innovation",
    description: "Discover Clark University’s commitment to academic excellence and innovative educational programs.",
    keywords: "Clark University, academic excellence, innovative education"
  },
  {
    path: "/University/InternationalUniversity",
    name: "International University",
    element: InternationalUniversity,
    exact: true,
    title: "International University - Global Education Opportunities",
    description: "Find out more about International University and its global education opportunities for students worldwide.",
    keywords: "International University, global education, international programs"
  },
  {
    path: "/University/ESDESBusinessSchool",
    name: "ESDES Business School",
    element: ESDESBusinessSchool,
    exact: true,
    title: "ESDES Business School - Business Education Excellence",
    description: "Explore ESDES Business School’s programs focused on delivering excellence in business education.",
    keywords: "ESDES Business School, business education, excellence"
  },
  {
    path: "/University/Aivancity",
    name: "Aivancity",
    element: Aivancity,
    exact: true,
    title: "Aivancity - Cutting-Edge Educational Programs",
    description: "Learn about Aivancity and its cutting-edge educational programs designed to advance your career.",
    keywords: "Aivancity, cutting-edge education, career advancement"
  },
  {
    path: "/University/NortheasternUniversity",
    name: "Northeastern University",
    element: NortheasternUniversity,
    exact: true,
    title: "Northeastern University - Comprehensive Academic Programs",
    description: "Discover Northeastern University’s comprehensive academic programs and its impact on student success.",
    keywords: "Northeastern University, academic programs, student success"
  },
  {
    path: "/University/HaagaHeliaUniversity",
    name: "Haaga Helia University",
    element: HaagaHeliaUniversity,
    exact: true,
    title: "Haaga Helia University - Leading Business Education",
    description: "Find out more about Haaga Helia University and its leading business education programs.",
    keywords: "Haaga Helia University, business education, leading programs"
  },
  {
    path: "/University/VaasaUniversity",
    name: "Vaasa University",
    element: VaasaUniversity,
    exact: true,
    title: "Vaasa University - Quality Higher Education",
    description: "Explore Vaasa University’s commitment to providing high-quality higher education across various disciplines.",
    keywords: "Vaasa University, quality education, higher education"
  },
  {
    path: "/University/MetropoliaUniversity",
    name: "Metropolia University",
    element: MetropoliaUniversity,
    exact: true,
    title: "Metropolia University - Innovative Learning Solutions",
    description: "Get information about Metropolia University and its innovative learning solutions designed for modern students.",
    keywords: "Metropolia University, innovative learning, modern education"
  },
  {
    path: "/University/SeinajokiUniversity",
    name: "Seinajoki University",
    element: SeinajokiUniversity,
    exact: true,
    title: "Seinajoki University - Diverse Academic Programs",
    description: "Learn about Seinajoki University’s diverse academic programs and its approach to higher education.",
    keywords: "Seinajoki University, academic programs, higher education"
  },
  {
    path: "/University/InternationalBusinessUniversity",
    name: "International Business University",
    element: InternationalBusinessUniversity,
    exact: true,
    title: "International Business University - Global Business Education",
    description: "Discover International Business University’s global business education programs and its impact on the international business landscape.",
    keywords: "International Business University, global business, education programs"
  },
  {
    path: "/University/ColoradoStateUniversity",
    name: "Colorado State University",
    element: ColoradoStateUniversity,
    exact: true,
    title: "Colorado State University - Premier Higher Education",
    description: "Find detailed information about Colorado State University and its premier higher education programs.",
    keywords: "Colorado State University, higher education, academic programs"
  },
  {
    path: "/hcm/liba",
    name: "University Courses",
    element: UniversityCourses,
    exact: true,
    title: "University Courses - Explore a Range of Programs",
    description: "Browse a variety of university courses available through My App, tailored to meet diverse educational needs.",
    keywords: "university courses, program listings, educational opportunities"
  },
  {
    path: "/PGDM/liba",
    name: "PGDM",
    element: PGDM,
    exact: true,
    title: "PGDM - Comprehensive Management Programs",
    description: "Learn about the PGDM programs offered by our partner institutions and how they can advance your management career.",
    keywords: "PGDM, management programs, career advancement"
  },
  {
    path: "/BDU",
    name: "BDU",
    element: BDU,
    exact: true,
    title: "BDU - Academic Excellence and Opportunities",
    description: "Explore BDU and its academic offerings that provide excellence in education and various opportunities for students.",
    keywords: "BDU, academic excellence, student opportunities"
  },
  {
    path: "/AboutusPageSample",
    name: "About Us Page Sample",
    element: AboutusPageSample,
    exact: true,
    title: "About Us Page Sample - Preview Our Company Information",
    description: "View a sample of our About Us page to understand how we present company information and engage with our audience.",
    keywords: "about us page, company information, sample preview"
  },
  {
    path: "*",
    name: "NotFound",
    element: NotFound,
    exact: false, // No need for exact match for 404 route
    title: "404 - Page Not Found",
    description: "This page does not exist.",
    keywords: "404, not found, error",
  },
];

export default routes;
